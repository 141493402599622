/* Estilos gerais para o container */
.edit-container {
  padding: 20px;
}

/* Estilo para o formulário de atualização */
.update-forms {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Estilo para o título do formulário */
.myaccount-content {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Estilo para o botão */
.btn-primary {
  background-color: #006400;;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block; /* Certifique-se de que o botão não ocupe toda a largura disponível */
  text-align: center;
}

.btn-primary:hover {
  background-color: #006400;
}

/* Estilo para mensagens de erro */
.errors {
  color: red;
  margin-top: 10px;
}

/* Estilo para os campos do formulário */
.form-control {
  margin-bottom: 15px;
}

/* Estilo para labels obrigatórios */
.required {
  color: red;
}

/* Estilo para o editor */
.editor .ql-container {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* Estilo adicional para garantir que o botão esteja alinhado corretamente */
.form-group.col-sm-12.text-right {
  display: flex;
  justify-content: flex-end; /* Alinha o botão à direita */
  margin-top: 20px; /* Adiciona um espaço superior */
}

@media (max-width: 768px) {
  /* Estilo responsivo para telas menores */
  .btn-primary {
    width: 100%; /* Faz o botão ocupar a largura total em telas menores */
    box-sizing: border-box; /* Inclui padding e border na largura total */
  }
}
