@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
body > :first-child {
    padding: 0;
    background-color: #fff;
    top: 0;
    width: 100%;
    z-index: 1000;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-family: "Bebas Neue", sans-serif;
}

.container1 {
    display: flex;
    flex-direction: column; /* Garante que os itens fiquem uma abaixo da outra */
    align-items: center;    /* Centraliza horizontalmente */
    justify-content: center; /* Centraliza verticalmente */
    height: 30vh;           /* Define altura total da tela */
    text-align: center;      /* Centraliza o texto */
    gap: 10px;
}
/* Logo */
.logo1 {
    max-width: 300px; /* Tamanho máximo para limitar a logo */
    width: 100%;
  }
  
  .logo1-img {
    width: 100%;
    height: auto;
    object-fit: contain; /* Garante proporções corretas */
  }



nav {
    display: flex;
    align-items: center;
}


.nav1-list {
    list-style: none; /* Remove marcadores da lista */
    padding: 0;
    margin: 0;
    display: flex; /* Coloca os itens da lista em coluna */
    gap: 10px;              /* Espaçamento entre itens */
  }

.nav1-list li {
    margin-right: 20px;
}

.nav1-list a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

/* Links principais */
.nav1-list li a {
    font-weight: bold;
    text-decoration: none;
    font-size: 22px;
    color: #FF4500;
    transition: color 0.3s ease;
}

.nav1-list li a:hover {
    color: #FFA07A;
}

.nav-btn {
    padding: 5px;
    display: none;
    font-size: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;

}    
.nav-close-btn {
    display: none;
    background: transparent;
}
/* Dropdown */
.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%; /* Alinha abaixo do link principal */
    left: 0;
    list-style: none;
    background-color: #ffffff;
    padding: 0.5rem 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    min-width: 150px;
    z-index: 1000;
}

.dropdown-menu li {
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 15px;
    color: #FF4500;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-weight: bold;
  }
  
  .dropdown-menu li:hover {
    background-color: #f0f0f0;
  }

.dropdown-menu li a {
    color: #FF4500;
    font-size: 15px;
    text-decoration: none;
}


.dropdown:hover .dropdown-menu {
    display: block;
}

.btn-primary {
    color: #FF4500;
    font-size: 15px;
    text-decoration: none;
    background-color: #ffffff;
    list-style: none;
    font-weight: bold;
}

.btn-primary :hover {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}



@media (max-width: 1281px) {
    header {
        height: auto;
        padding: 20px;
    }

    .container1 {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .logo1-img {
        font-size: 24px;
        margin: 0;
    }

    .nav1 ul li a {
        font-size: 15px;
    }

    .lang-container {
        padding: 10px;
    }

    .hero {
        flex-direction: column-reverse;
        height: auto;
    }
    
    .text-content {
        width: 100%;
        padding: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    
    .image-content {
        width: 100%;
    }

    .carousel img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
   
}

@media (max-width: 768px) {
    nav {
        display: none;
        position: absolute;
        top: 20vh;
        left: 0;
        width: 100%;
        height: 80vh;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        background-color: #f0f0f0;
        z-index: 999;
        transition: all 0.3s ease-in-out;
    
    }

    .nav1-list {
        flex-direction: column;
    }

    .nav1-list li {
        margin-bottom: 20px;
    }

    .nav-btn {
        display: block;
    }

    .responsive_nav {
        display: flex;
    }

    

   
}
