/* Sobreposição do modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Cor de fundo semitransparente */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Garante que o modal esteja acima de outros elementos */
    
  }
  
  /* Conteúdo do modal */
  .modal-content1 {
    background: #fff; /* Fundo branco */
    border-radius: 8px; /* Cantos arredondados */
    padding: 20px; /* Espaçamento interno */
    max-width: 500px; /* Largura máxima */
    width: 90%; /* Largura responsiva */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra leve */
    display: flex;
    flex-direction: column; /* Empilha o conteúdo verticalmente */
    position: relative; /* Necessário para o posicionamento do botão de fechar */
    max-height: 80vh; /* Altura máxima do modal */
    overflow-y: auto; /* Adiciona rolagem se necessário */
  }
  
  /* Estilos do título do modal */
  .modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  /* Estilos para o texto dentro do modal */
  .modal-content p {
    margin: 10px 0;
    color: #666;
  }
  
  /* Estilos do botão de fechar */
  .modal-footer {
    margin-top: auto; /* Empurra o botão para o fundo do modal */
    display: flex;
    justify-content: center; /* Centraliza o botão horizontalmente */
    padding-top: 10px; /* Espaço acima do botão */
  }
  
  .modal-footer button {
    padding: 10px 15px;
    background-color: #006400; /* Cor de fundo do botão */
    color: #fff; /* Cor do texto */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Transição suave para hover */
  }
  
  /* Efeito ao passar o mouse sobre o botão */
  .modal-footer button:hover {
    background-color: #0056b3; /* Cor de fundo mais escura */
  }
  
  /* Estilos para links no modal */
  .modal-content a {
    color: #007bff; /* Cor dos links */
    text-decoration: none; /* Remove sublinhado dos links */
  }
  
  .modal-content a:hover {
    text-decoration: underline; /* Sublinha o link ao passar o mouse */
  }
  