/* Estilo para o título dos posts */
.List1 {
    display: flex;
    flex-direction: column; /* Empilha verticalmente */
    align-items: center; /* Centraliza horizontalmente (opcional) */
    gap: 15px; 
    margin: auto;
    background-image: linear-gradient(to bottom, #ffcc99, #ffffff);
    
}

.post__title {
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.postsList2__title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

.mb-4  {
    width: 100%;
    height: auto;
    text-align: center;
    position: absolute;
    top: 25px;
  }

.button-green {
  
    background-color: #006400;
    color: white;
    padding: 10px 20px;
    border: none;
    top: 20px;
    border-radius: 5px;
    margin-right: 10px; /* Espaço entre os botões */
    cursor: pointer;
}

.button-green:last-child {
    margin-right: 0; /* Remove margem do último botão */
}

.postsList2__container {
    width: 100%;
    height: auto;
    text-align: center;
    position: relative;
    top: 150px; 
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ajuste automático do número de colunas */
    gap: 16px;
    padding: 20px;
    overflow: auto; /* Evite que o conteúdo "corte" */
}

/* Estilo para cada card */
.post__list {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
}

/* Efeito de hover para os cards */
.post__list:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Estilo para descrição dos posts */
.post__description {
    margin: 10px 0;
    padding: 0 10px;
    line-height: 1.6;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    word-break: break-word;
}

/* Estilo para detalhes dos posts */
.post__details {
    font-size: 0.9em;
    color: #666;
    margin-top: 10px;
}

/* Estilo para a imagem do post */
.post__image {
    width: 100%;
    max-width: 350px;
    text-align: center;
    margin-bottom: 15px;
}

.post-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.post__image-source {
    font-size: 0.875rem;
    color: #777;
    margin-top: 5px;
    font-style: italic;
    text-align: left;
}

/* Estilo para ações de botão dentro do post */
.post__actions {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* Estilo para o botão de editar */
button.btn-edit {
    padding: 8px 12px;
    font-size: 0.9em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ffc107;
    color: #fff;
    transition: background-color 0.3s ease;
}

button.btn-edit:hover {
    background-color: #e0a800;
}

/* Estilo para o botão de deletar */
button.btn-delete {
    padding: 8px 12px;
    font-size: 0.9em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #dc3545;
    color: #fff;
    transition: background-color 0.3s ease;
}

button.btn-delete:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {

}
