@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
/* Observa.module.css */
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family:"Bebas Neue", sans-serif;
}

main{
    margin-top: 20px;
    background-image: linear-gradient(to bottom, #ffcc99, #ffffff);
    background-size: cover;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.textSection {
    
    margin: 20px;
    padding: 20px;
  }

.textSection p {

    font-size: 20px;
    text-align: justify; 
    line-height: 1.5; 
    font-family: "Public Sans", sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

.textSection a {
    text-decoration: none;
    color:#333;
}



.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.container p {
    text-decoration: none;
}

.imageCenter1 {
    text-align: center;
    margin-top: 40px;
    width: 100%;
    height: 100%;
}

.imageCenter2 {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    transform: scale(0.9)
}

.imageCenter3 {
    text-align: center;
    margin-top: 40px;
    width: 100%;
    height: 100%;
}


.textSection {
    margin: 20px 0;
    
}

.textSection h3 {
    color: #006400;
    font-weight: bold;
    font-family: "Barlow Condensed", sans-serif;
}

.imagesSideBySide {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}


.observaimg img {
    max-width: 48%; /* Ajusta o tamanho das imagens para ficarem lado a lado */
}

.noUnderline {
    text-decoration: none; /* Remove o sublinhado de todos os elementos dentro do link */
    color: inherit;        /* Mantém a cor do texto padrão */
  }
  
.caption {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
    text-align: center;
}

.noUnderline p {
    text-decoration: none; /* Remove o sublinhado apenas do <p> */
}

@media (max-width: 768px) {
    .imageCenter1 {
        width: 100%;
        margin: auto;
            
        
    }

    .imageCenter1 img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .imageCenter2 {
        width: 100%;
        margin: auto;
    }

    .imageCenter2 img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .imagesSideBySide {
       width: 100%;
       flex-direction: column;
       justify-content: space-between;
       align-items: center;
       
       
    }
    .observaimg2 {
        margin-top: 50px;
    }
    .imageCenter3 {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        align-items: center;
        transform: scale(0.9);

        
    }
    p {
        font-size: 14px;
        text-align: left; /* Ou justify se preferir */
        
    }
  
}
  
