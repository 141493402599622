@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family:"Bebas Neue", sans-serif;
}

main{
    margin-top: 20px;
    background-image: linear-gradient(to bottom, #ffcc99, #ffffff);
    background-size: cover;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.sobre {
    
    margin: 20px;
    padding: 20px;
  }

.sobre p {
    font-size: 20px;
    text-align: justify; 
    line-height: 1.5; 
    font-family: "Public Sans", sans-serif;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}


.sobre span {
    font-weight: bold;
}

.sobre h1 {
    display: flex;
    font-family: "Barlow Condensed", sans-serif;
    /*color: #006400; /* Um verde escuro */
    /*color: #FF8C00; /* Laranja  */
    color: #8B0000; /* Vermelho escuro */
    /*color: #333333; /* Cinza escuro,*/
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
}

.sobre h2 {
    display: flex;
    font-family: "Barlow Condensed", sans-serif;
    color: #006400; /* Um verde escuro */
    /*color: #FF8C00; /* Laranja  */
    /*color: #8B0000; /* Vermelho escuro */
    /*color: #333333; /* Cinza escuro,*/
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: bold;
}

.sobre h5 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 21px;
    font-family: "Barlow Condensed", sans-serif;
    

}

.sobre a {
    text-decoration: none;
    color:#333;
}


@media (max-width: 768px) {
    p {
        font-size: 14px;
        text-align: left; /* Ou justify se preferir */
    }
}