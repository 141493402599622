@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.contact-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5vh 5vw;
    margin-top: 5vh;
    border-radius: 15px;
}

.contact-info {
    flex: 1;
    margin-right: 5vw;
}

.contact-info h2 {
    font-size: 2.5rem;
    margin-bottom: 2vh;
    font-family: "Barlow Condensed", sans-serif;
}

.contact-info p {
    display: flex;
    align-items: center;
    font-size: 17px;
    line-height: 1.6;
    margin-bottom: 2vh;
    font-family: "Public Sans", sans-serif;;
}

.contact-info a {
    text-decoration: none;
    color: black;
    font-size: 22px;
} 
.contact-icon {
    width: 20px;
    height: 20px;
    margin-right: 1vw;
}

.contact-form {
    flex: 1;
}

form {
    display: flex;
    flex-direction: column;
}

.input-group {
    display: flex;
    gap: 2vw;
    margin-bottom: 2vh;
}

.rounded-input {
    padding: 1vh 2vw;
    border-radius: 15px;
    border: 1px solid #ccc;
    font-size: 17px;
    outline: none;
    flex: 1;
}

.half-input {
    width: calc(50% - 1vw);
}

.full-input {
    width: 100%;
    margin-bottom: 2vh;
}

.submit-btn {
    padding: 1.2vh 0;
    background-color: #000000;
    color: #fff;
    border: none;
    border-radius: 15px;
    font-size: 17px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.submit-btn:hover {
    background-color: #2f2f2f;
}

@media (max-width: 1291px) {
    .contact-info p {
        font-size: 14px;
    }

    .rounded-input {
        padding: 0.5vh 1vw;
        font-size: 14px;
    }

    .submit-btn {
        padding: 0.7vh 0;
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .contact-section {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;
    }

    .contact-form {
        width: 100%;
    }

    .contact-info {
        display: flex;
        column-gap: 100px;
        align-items: center;
        flex-wrap: wrap;
    }

    .contact-info p {
        font-size: 13px;
    }

    .rounded-input {
        padding: 0.5vh 1vw;
        font-size: 13px;
    }

    .submit-btn {
        padding: 0.6vh 0;
        font-size: 13px;
        font-weight: bold;
    }
}