@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bebas+Neue&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Press+Start+2P&family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.mission-values {
    display: flex;
    height: auto;
    margin-bottom: 60px;
}

.text-container1 {
    width: 55%;
    background-color: #007030;
    padding: 50px 80px;
    color: white;
    text-align: end;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.text-container1 h2 {
    margin-bottom: 20px;
    font-size: 28px;
    font-family: "Barlow Condensed", sans-serif;
}

.text-container1 p {
    font-size: 17px;
    line-height: 1.5;
    font-family: "Public Sans", sans-serif;
}

.text-container1 button {
    padding: 10px 25px;
    color: #fff;
    background-color: black;
    border-radius: 15px;
    font-weight: bold;
    font-size: 17px;
    margin-top: 25px;
    cursor: pointer;
}

.text-container1 button a:hover {
    text-decoration: underline;
}

.text-container1 button a {
    text-decoration: none;
    color: inherit;
}

.image-container1 {
    width: 45%;
}

.image-container1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media (max-width: 1291px) {
    .mission-values {
        height: auto;
        margin-bottom: 40px;
    }

    .text-container {
        padding: 20px;
        padding: 25px 50px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .text-container h2 {
        margin-bottom: 7px;
        font-size: 20px;
    }

    .text-container p {
        font-size: 13px;
    }

    .text-container button {
        padding: 5px 10px;
        font-size: 12px;
        margin-top: 7px;
        cursor: pointer;
    }

    .image-container {
        width: 45%;
    }

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
}

@media (max-width: 768px) {
    .mission-values {
        display: block;
        flex-wrap: wrap;
        gap: 20px;
        align-items: center;
        padding: 20px;
    }

    .text-container1 {
        padding: 20px;
        padding: 15px 25px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0px;
        width: 100%;
        height: 100%;
    }

    .text-container1 h2 {
        margin-bottom: 10px;
        font-size: 18px;
    }

    .text-container1 p {
        font-size: 13px;
        word-break: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
    }

    .text-container1 button {
        padding: 5px 10px;
        font-size: 13px;
        margin-top: 10px;
        cursor: pointer;
    }

    .image-container1 {
        width: 100%;
        
    }

    .image-container1 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
    .image-container1,
    .text-container1 {
        flex: 1;
    }
}

